import React, { useEffect, useState, useRef } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';
import {
  AppBar,
  Toolbar,
  MenuItem,
  IconButton,
  Drawer,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@mui/material';
import LoadingBar from 'react-redux-loading-bar';
import queryString from 'query-string';
import * as authorizationActions from '../../../redux/actions/authorizationActions';
import getNotification from '../../../redux/actions/notificationAction';
import AuthorizationService from '../../../authorization/AuthorizationService';
import { showSuccessSnackbar } from '../../../redux/actions/snackbarActions';
import {
  AdminSubUserRoles,
  CompanySubUserRoles,
  UserTypes,
} from '../../../common/roles';
import { urlParseOptions } from '../../../helpers/urlHelper';
import {
  CANDIDATE_STATUS_CONST,
  // COMMUNITY_URL,
} from '../../../common/constant';
import {
  projectTypeModalOpenAction,
  updateProjectAddModalOpenAction,
} from '../../../redux/actions/projectActions';
import NavbarMobile from './NavbarMobile';
import {
  activeCredits,
  addTalent,
  admins,
  aiMatch,
  allCompanies,
  allCompanyUsers,
  allPendingTalent,
  allProjects,
  allTalent,
  allTeam,
  companyCredits,
  creditLog,
  home,
  integrations,
  myProfile,
  taAssignedActivity,
  taDashboard,
  tags,
  interviewRequestList,
  taProjectOverview,
  taStageActivity,
  homeFb,
} from '../../../common/paths';
import { updateCompanyAddModalOpenAction } from '../../../redux/actions/companyActions';
import { updateCompanyUserAddModalOpenAction } from '../../../redux/actions/companyUserActions';
import { getAvatarName } from '../../../helpers/utilityHelper';
import Logo from '../../../assets/logos/grit-logo.svg';
import LogoFB from '../../../assets/logos/fb-logo.svg';
import IconComp from '../icons/IconComp';
import FigUser03 from '../../../assets/icons/figma-pro-2-8/users/FigUser03/FigUser03';
import FigBuilding05 from '../../../assets/icons/figma-pro-2-8/general/FigBuilding05/FigBuilding05';
import FigHourglass03 from '../../../assets/icons/figma-pro-2-8/time/FigHourglass03/FigHourglass03';
import FigPlus from '../../../assets/icons/figma-pro-2-8/general/FigPlus/FigPlus';
import FigUsers01 from '../../../assets/icons/figma-pro-2-8/users/FigUsers01/FigUsers01';
import FigUserPlus01 from '../../../assets/icons/figma-pro-2-8/users/FigUserPlus01/FigUserPlus01';
import FigStar01 from '../../../assets/icons/figma-pro-2-8/shapes/FigStar01/FigStar01';
import FigBriefcase01 from '../../../assets/icons/figma-pro-2-8/education/FigBriefcase01/FigBriefcase01';
import FigLogOut01 from '../../../assets/icons/figma-pro-2-8/general/FigLogOut01/FigLogOut01';
import FigMenu01 from '../../../assets/icons/figma-pro-2-8/general/FigMenu01/FigMenu01';
import FigCloudBlank01 from '../../../assets/icons/figma-pro-2-8/general/FigCloudBlank01/FigCloudBlank01';
import FigTag02 from '../../../assets/icons/figma-pro-2-8/financeAndECommerce/FigTag02/FigTag02';
import FigThumbsUp from '../../../assets/icons/figma-pro-2-8/alertsAndFeedback/FigThumbsUp/FigThumbsUp';
import { REACT_APP_IS_SANDBOX_ACCOUNT } from '../../../config/envConfig';
import MenuItems from './MenuItems';
import AccountMenu from './AccountMenu';
import {
  addCreditsModalOpenAction,
  generateOrderFormAddModalOpenAction,
} from '../../../redux/actions/creditLogActions';
import PurchaseCreditModal from '../../pages-content/credits/purchase-credit/PurchaseCreditModal';
import AddUpdateCreditModal from '../../pages-content/credits/add-credit/AddUpdateCreditModal';
import FigCreditCardPlus from '../../../assets/icons/figma-pro-2-8/financeAndECommerce/FigCreditCardPlus/FigCreditCardPlus';
import FigCreditCardRefresh from '../../../assets/icons/figma-pro-2-8/financeAndECommerce/FigCreditCardRefresh/FigCreditCardRefresh';
import FigReceipt from '../../../assets/icons/figma-pro-2-8/financeAndECommerce/FigReceipt/FigReceipt';
import FigCreditCard01 from '../../../assets/icons/figma-pro-2-8/financeAndECommerce/FigCreditCard01/FigCreditCard01';
import FigPresentationChart01 from '../../../assets/icons/figma-pro-2-8/charts/FigPresentationChart01/FigPresentationChart01';
import FigMail01 from '../../../assets/icons/figma-pro-2-8/communication/FigMail01/FigMail01';
import FigBuilding08 from '../../../assets/icons/figma-pro-2-8/general/FigBuilding08/FigBuilding08';

const Navbar = ({
  history,
  actions,
  role,
  isPrivate,
  profilePic,
  companyName,
  companyLogo,
  username,
  subUserRole,
  creditLogList,
}) => {
  const [showAddCreditModal, setShowAddCreditModal] = useState(false);
  const [showPurchaseCreditModal, setShowPurchaseCreditModal] = useState(false);
  const [adminCompaniesMenuAnchorEl, setAdminCompaniesMenuAnchorEl] =
    useState(null);
  const [adminCandidateMenuAnchorEl, setAdminCandidateMenuAnchorEl] =
    useState(null);
  const [adminProjectMenuAnchorEl, setAdminProjectMenuAnchorEl] =
    useState(null);
  const [, setShowHideMenuBar] = useState(null);
  const [dashboardMenuEl, setDashboardMenuEl] = useState(null);
  const hideMenuBarUrl = ['/auth/login', '/community/auth/signup'];
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [tNav] = useTranslation('navbar');
  const [tAuth] = useTranslation('auth');
  const accountMenuRef = useRef();

  const searchMenu = {
    title: tNav('navLinks.search'),
    path: '/search',
    allow: role === UserTypes.Admin || role === UserTypes.Company,
    allowingPaths: ['/search'],
    id: 'navbar-search-btn',
  };

  const companyMenu = {
    title: tNav('navLinks.companies.title'),
    allow: role === UserTypes.Admin,
    allowingPaths: [allCompanies, allCompanyUsers],
    clickFunc: (event) =>
      setAdminCompaniesMenuAnchorEl(
        adminCompaniesMenuAnchorEl !== event.currentTarget
          ? event.currentTarget
          : null
      ),
    id: 'navbar-companies-menu-btn',
    menuList: [
      {
        id: 'navbar-all-companies-btn',
        name: tNav('navLinks.companies.menuList.allCompanies'),
        path: allCompanies,
        icon: <IconComp icon={<FigBuilding05 />} />,
        allow: true,
      },
      {
        id: 'navbar-pending-companies-btn',
        name: tNav('navLinks.companies.menuList.pendingCompanies'),
        path: {
          pathname: allCompanies,
          search: queryString.stringify({ status: [0] }, urlParseOptions),
        },
        icon: <IconComp icon={<FigHourglass03 />} />,
        allow: true,
      },
      {
        id: 'navbar-add-company-btn',
        name: tNav('navLinks.companies.menuList.addCompany'),
        path: allCompanies,
        icon: <IconComp icon={<FigPlus />} />,
        action: () => actions.updateCompanyAddModalOpen(true),
        actionRedirectBlockPath: allCompanies,
        allow: true,
      },
      {
        hasDivider: true,
      },
      {
        id: 'navbar-all-company-users-btn',
        name: tNav('navLinks.companies.menuList.allCompanyUsers'),
        path: allCompanyUsers,
        icon: <IconComp icon={<FigUsers01 />} />,
        allow: true,
      },
      {
        id: 'navbar-add-company-user-btn',
        name: tNav('navLinks.companies.menuList.addCompanyUsers'),
        path: allCompanyUsers,
        icon: <IconComp icon={<FigUserPlus01 />} />,
        action: () => actions.updateCompanyUserAddModalOpen(true),
        actionRedirectBlockPath: allCompanyUsers,
        allow: true,
      },
    ],
  };

  const dashboardMenu = {
    title: tNav('navLinks.dashboard.title'),
    allow: role === UserTypes.Admin,
    allowingPaths: [taDashboard],
    clickFunc: (event) =>
      setDashboardMenuEl(
        dashboardMenuEl !== event.currentTarget ? event.currentTarget : null
      ),
    id: 'navbar-dashboard-menu-btn',
    menuList: [
      {
        id: 'navbar-dashboard-weekly-report-btn',
        name: tNav('navLinks.dashboard.action-log'),
        path: taDashboard,
        icon: <IconComp icon={<FigPresentationChart01 />} />,
        allow: true,
      },
      {
        id: 'navbar-dashboard-project-overview',
        name: tNav('navLinks.dashboard.project-overview'),
        path: taProjectOverview,
        icon: <IconComp icon={<FigPresentationChart01 />} />,
        allow: true,
      },
      {
        id: 'navbar-dashboard-assigned-activity-log',
        name: tNav('navLinks.dashboard.ta-assigned-log'),
        path: taAssignedActivity,
        icon: <IconComp icon={<FigPresentationChart01 />} />,
        allow: true,
      },
      {
        id: 'navbar-dashboard-stage-activity-log',
        name: tNav('navLinks.dashboard.ta-stage-log'),
        path: taStageActivity,
        icon: <IconComp icon={<FigPresentationChart01 />} />,
        allow: true,
      },
      {
        id: 'navbar-interview-request',
        name: tNav('navLinks.dashboard.interview-request'),
        path: interviewRequestList,
        icon: <IconComp icon={<FigMail01 />} />,
        allow: true,
      },
    ],
  };

  const projectMenu = {
    title: tNav('navLinks.projects.title'),
    allow:
      role === UserTypes.Admin ||
      role === UserTypes.Company ||
      role === UserTypes.RaasCompany,
    allowingPaths: [allProjects],
    clickFunc: (event) =>
      setAdminProjectMenuAnchorEl(
        adminProjectMenuAnchorEl !== event.currentTarget
          ? event.currentTarget
          : null
      ),
    id: 'navbar-projects-menu-btn',
    menuList: [
      {
        id: 'navbar-all-projects-btn',
        name: tNav('navLinks.projects.menuList.allProjects'),
        path: allProjects,
        icon: <IconComp icon={<FigBriefcase01 />} />,
        allow:
          role === UserTypes.Admin ||
          role === UserTypes.Company ||
          role === UserTypes.RaasCompany,
      },
      {
        id: 'navbar-internal-projects-btn',
        name: tNav('navLinks.projects.menuList.internalProjects'),
        path: {
          pathname: allProjects,
          search: queryString.stringify(
            { internal_project: true },
            urlParseOptions
          ),
        },
        icon: <IconComp icon={<FigBuilding08 />} />,
        allow: role === UserTypes.Admin,
      },
      {
        id: 'navbar-pending-projects-btn',
        name: tNav('navLinks.projects.menuList.myProjects'),
        path: {
          pathname: allProjects,
          search: queryString.stringify({ my_project: true }, urlParseOptions),
        },
        icon: <IconComp icon={<FigStar01 />} />,
        allow:
          role === UserTypes.Admin ||
          role === UserTypes.Company ||
          role === UserTypes.RaasCompany,
      },
      {
        id: 'navbar-assigned-advocate-projects-btn',
        name: tNav('navLinks.projects.menuList.assignedProjects'),
        path: {
          pathname: allProjects,
          search: queryString.stringify(
            { advocate_project: true },
            urlParseOptions
          ),
        },
        icon: <IconComp icon={<FigThumbsUp />} />,
        allow:
          role === UserTypes.Admin &&
          subUserRole === AdminSubUserRoles.Advocate,
      },
      {
        id: 'navbar-add-project-btn',
        name: tNav('navLinks.projects.menuList.addProject'),
        path: allProjects,
        icon: <IconComp icon={<FigPlus />} />,
        action: () =>
          role === UserTypes.RaasCompany
            ? actions.projectTypeModalOpen(true)
            : actions.updateProjectAddModalOpen(true),
        actionRedirectBlockPath: allProjects,
        allow:
          role === UserTypes.Admin ||
          role === UserTypes.Company ||
          role === UserTypes.RaasCompany,
      },
    ],
  };

  const talentMenu = {
    title: tNav('navLinks.talent.title'),
    allow: role === UserTypes.Admin,
    allowingPaths: [allTalent, allPendingTalent, addTalent, tags],
    clickFunc: (event) =>
      setAdminCandidateMenuAnchorEl(
        adminCandidateMenuAnchorEl !== event.currentTarget
          ? event.currentTarget
          : null
      ),
    id: 'navbar-candidates-menu-btn',
    menuList: [
      {
        id: 'navbar-all-candidates-btn',
        name: tNav('navLinks.talent.menuList.allTalent'),
        path: allTalent,
        icon: <IconComp icon={<FigUser03 />} />,
        allow: true,
      },
      {
        id: 'navbar-pending-candidates-btn',
        name: tNav('navLinks.talent.menuList.pendingTalent'),
        path: {
          pathname: allTalent,
          search: queryString.stringify(
            { status: [CANDIDATE_STATUS_CONST.PENDING_REQUEST] },
            urlParseOptions
          ),
        },
        icon: <IconComp icon={<FigHourglass03 />} />,
        allow: true,
      },
      {
        id: 'navbar-pending-activation-candidates-btn',
        name: tNav('navLinks.talent.menuList.pendingActivationTalent'),
        path: {
          pathname: allTalent,
          search: queryString.stringify(
            { status: [CANDIDATE_STATUS_CONST.PENDING_ACTIVATION] },
            urlParseOptions
          ),
        },
        icon: <IconComp icon={<FigHourglass03 />} />,
        allow: true,
      },
      {
        id: 'navbar-add-candidate-btn',
        name: tNav('navLinks.talent.menuList.addTalent'),
        path: addTalent,
        icon: <IconComp icon={<FigPlus />} />,
        allow: true,
      },
      {
        hasDivider: true,
      },
      {
        id: 'navbar-tags-btn',
        name: tNav('navLinks.talent.menuList.tags'),
        path: tags,
        icon: <IconComp icon={<FigTag02 />} />,
        allow: true,
      },
    ],
  };

  const creditMenu = {
    title: tNav('navLinks.credits.title'),
    allow: role === UserTypes.Admin,
    allowingPaths: [activeCredits, creditLog],
    clickFunc: (event) =>
      setAdminCandidateMenuAnchorEl(
        adminCandidateMenuAnchorEl !== event.currentTarget
          ? event.currentTarget
          : null
      ),
    id: 'navbar-credits-menu-btn',
    menuList: [
      {
        id: 'navbar-active-credits-btn',
        name: tNav('navLinks.credits.menuList.activeCredits'),
        path: activeCredits,
        icon: <IconComp icon={<FigCreditCard01 />} />,
        allow: true,
      },
      {
        id: 'navbar-credit-logs-btn',
        name: tNav('navLinks.credits.menuList.creditLog'),
        path: creditLog,
        icon: <IconComp icon={<FigCreditCardRefresh />} />,
        allow: true,
      },
      {
        id: 'navbar-add-credit-btn',
        name: tNav('navLinks.credits.menuList.addCredits'),
        path: creditLog,
        icon: <IconComp icon={<FigCreditCardPlus />} />,
        action: () => actions.addCreditsModalOpen(true),
        actionRedirectBlockPath: creditLog,
        allow: true,
      },
      {
        hasDivider: true,
      },
      {
        id: 'navbar-generate-order-form-btn',
        name: tNav('navLinks.credits.menuList.generateOrderForm'),
        path: creditLog,
        icon: <IconComp icon={<FigReceipt />} />,
        action: () => actions.generateOrderFormAddModalOpen(true),
        actionRedirectBlockPath: creditLog,
        allow: true,
      },
    ],
  };

  const adminMenu = {
    title: tNav('navLinks.admins'),
    path: admins,
    allow: role === UserTypes.Admin,
    allowingPaths: [admins],
    id: 'navbar-admins-menu-btn',
  };

  // const communityMenu = {
  //   title: tNav('navLinks.community'),
  //   href: COMMUNITY_URL,
  //   allow: role === UserTypes.Company || role === UserTypes.RaasCompany,
  //   allowingPaths: [''],
  //   id: 'navbar-community-menu-btn',
  // };

  const aiMatchMenu = {
    title: 'Ai Match',
    path: aiMatch,
    allow: role === UserTypes.Admin,
    allowingPaths: [''],
    id: 'ai-match-menu-btn',
  };

  const menuArr = [
    searchMenu,
    companyMenu,
    projectMenu,
    talentMenu,
    creditMenu,
    adminMenu,
    dashboardMenu,
    // REACT_APP_IS_SANDBOX_ACCOUNT === 'false' ? communityMenu : null,
    aiMatchMenu,
  ];

  useEffect(() => {
    if (creditLogList.isAddCreditsModalOpen) {
      setShowAddCreditModal(true);
      actions.addCreditsModalOpen(false);
    }

    if (creditLogList.isGenerateOrderFormModalOpen) {
      setShowPurchaseCreditModal(true);
      actions.generateOrderFormAddModalOpen(false);
    }
  }, [creditLogList, actions]);

  const verifyAccount = async () => {
    const params = new URLSearchParams(history.location.search);
    const data = {
      code: params.get('code'),
    };
    try {
      await actions.verify(data);
      await actions.showSuccessSnackbar(
        tAuth('success.emailVerify'),
        'success'
      );
      history.push('/auth/login');
    } catch (err) {
      if (err) {
        await actions.showSuccessSnackbar(
          tAuth(err[0] ? err[0].message : err),
          'error'
        );
      }
    }
  };

  useEffect(() => {
    if (history.location.pathname) {
      if (history.location.pathname === '/auth/confirm-email') {
        (async () => {
          await verifyAccount();
        })();
      }

      const decodedPath = decodeURIComponent(history.location.pathname);
      const path = decodedPath.split('//');
      setShowHideMenuBar(false);
      if (hideMenuBarUrl.includes(path[0])) {
        setShowHideMenuBar(true);
      }
    }

    if (role === UserTypes.Admin) {
      actions.getNotification();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMenuClose = () => {
    setAdminCompaniesMenuAnchorEl(null);
    setAdminCandidateMenuAnchorEl(null);
    setAdminProjectMenuAnchorEl(null);
    setDrawerOpen(false);
  };

  const handleLogout = async () => {
    const redirectUrl = '/auth/login';
    try {
      handleMenuClose();
      await actions.logoff(history, redirectUrl);
    } catch (err) {
      history.push('/auth/login');
      handleMenuClose();
    }
  };

  const navigateToUrl = (el, currentPath) => {
    handleMenuClose();
    if (
      !el.action ||
      (el.action && history.location.pathname !== currentPath)
    ) {
      if (el.path.pathname && el.path.search) {
        history.push({
          pathname: el.path.pathname ? el.path.pathname : el.path,
          search: el.path.search ? el.path.search : null,
        });
      } else {
        history.push({
          pathname: el.path,
        });
      }
    }

    if (el.action) {
      el.action();
    }
  };

  const myProfileMenuItem = (
    <MenuItem
      component={Link}
      to={myProfile}
      id='navbar-my-account-profile-btn'
      key='navbar-my-account-profile-btn'
    >
      <ListItemIcon>
        <IconComp icon={<FigUser03 />} />
      </ListItemIcon>
      <ListItemText>{tNav('navLinks.myAccount.menuList.profile')}</ListItemText>
    </MenuItem>
  );

  const logoutMenuItem = (
    <MenuItem
      onClick={handleLogout}
      id='navbar-my-account-logout-btn'
      key='navbar-my-account-logout-btn'
    >
      <ListItemIcon>
        <IconComp icon={<FigLogOut01 />} />
      </ListItemIcon>
      <ListItemText>{tNav('navLinks.myAccount.menuList.logout')}</ListItemText>
    </MenuItem>
  );

  const companyAccountMenu = (
    <ul className={`dropdown menu-account ${dropdown ? 'show' : ''}`}>
      {AuthorizationService.isAuthenticated()
        ? [
            myProfileMenuItem,
            (role === UserTypes.Company || role === UserTypes.RaasCompany) &&
              subUserRole === CompanySubUserRoles.Admin && (
                <MenuItem
                  component={Link}
                  to={allTeam}
                  id='navbar-my-account-team-btn'
                >
                  <ListItemIcon>
                    <IconComp icon={<FigUsers01 />} />
                  </ListItemIcon>
                  <ListItemText>
                    {tNav('navLinks.myAccount.menuList.team')}
                  </ListItemText>
                </MenuItem>
              ),
            role === UserTypes.RaasCompany && (
              <MenuItem
                component={Link}
                to={companyCredits}
                id='navbar-my-account-credits-btn'
              >
                <ListItemIcon>
                  <IconComp icon={<FigCreditCard01 />} />
                </ListItemIcon>
                <ListItemText>
                  {tNav('navLinks.myAccount.menuList.credits')}
                </ListItemText>
              </MenuItem>
            ),
            role === UserTypes.Company &&
              subUserRole === CompanySubUserRoles.Admin &&
              REACT_APP_IS_SANDBOX_ACCOUNT === 'false' && (
                <MenuItem
                  component={Link}
                  to={integrations}
                  id='navbar-my-account-integration-btn'
                >
                  <ListItemIcon>
                    <IconComp icon={<FigCloudBlank01 />} />
                  </ListItemIcon>
                  <ListItemText>
                    {tNav('navLinks.myAccount.menuList.integration')}
                  </ListItemText>
                </MenuItem>
              ),
            <Divider key='company-account-menu' />,
            logoutMenuItem,
          ]
        : []}
    </ul>
  );

  const candidateAccountMenu = (
    <ul className={`dropdown menu-account ${dropdown ? 'show' : ''}`}>
      {AuthorizationService.isAuthenticated() ? [logoutMenuItem] : []}
    </ul>
  );

  const adminAccountMenu = (
    <ul className={`dropdown menu-account ${dropdown ? 'show' : ''}`}>
      {AuthorizationService.isAuthenticated() && [
        myProfileMenuItem,
        <Divider key='admin-account-menu' />,
        logoutMenuItem,
      ]}
    </ul>
  );

  const handleHamburgerMenu = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setDrawerOpen(open);
  };

  const onMouseEnter = () => {
    setDropdown(true);
  };

  const onMouseLeave = () => {
    setDropdown(false);
  };

  let showFBLogo = false;

  const urlsToCheck = [
    'auth/login',
    'auth/company/signup/3',
    'auth/company/signup/2',
    'auth/company/signup/1',
  ];

  urlsToCheck.forEach((item) => {
    if (history.location.pathname.includes(item)) {
      showFBLogo = true;
    }
  });

  return (
    <>
      <AppBar className='cus-navbar' position='sticky'>
        <Toolbar className='tool-bar-wrapper custom-container'>
          <div className='menu-wrapper'>
            {role !== UserTypes.Candidate &&
              AuthorizationService.isAuthenticated() && (
                <IconButton
                  aria-label='Open side navigation'
                  className='btn-invert navbar-mobile-display hamburger-menu-btn'
                  onClick={handleHamburgerMenu(true)}
                >
                  <IconComp icon={<FigMenu01 />} />
                </IconButton>
              )}

            {!showFBLogo && (
              <Link to={home} className='nav-bar-logo'>
                <img src={Logo} alt={tNav('title')} />
              </Link>
            )}

            {showFBLogo && (
              <a href={homeFb} className='nav-bar-logo'>
                <img src={LogoFB} alt={tNav('title')} />
              </a>
            )}

            {/* render all navbar menu here */}
            {isPrivate && (
              <div className='navbar-non-mobile-display'>
                <ul className='menus'>
                  {menuArr.map((menu) => {
                    return menu?.allow ? (
                      <MenuItems
                        menu={menu}
                        key={menu.id}
                        navigateToUrl={navigateToUrl}
                        history={history}
                      />
                    ) : null;
                  })}
                </ul>
              </div>
            )}
          </div>

          {isPrivate && (
            <AccountMenu
              userPic={profilePic}
              userAvatarName={getAvatarName(username)}
              companyLogo={
                role === UserTypes.Company || role === UserTypes.RaasCompany
                  ? companyLogo
                  : null
              }
              companyAvatarName={getAvatarName(companyName)}
              title={username?.split(' ')?.[0] || ''}
              subTitle={companyName ? companyName.split(' ')[0] : null}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              ref={accountMenuRef}
              companyAccountMenu={companyAccountMenu}
              candidateAccountMenu={candidateAccountMenu}
              adminAccountMenu={adminAccountMenu}
              role={role}
              dropdown={dropdown}
            />
          )}
        </Toolbar>

        <LoadingBar
          style={{
            backgroundColor: '#7F56D9',
            height: '5px',
            position: 'absolute',
          }}
        />
      </AppBar>

      <Drawer
        anchor='left'
        open={drawerOpen}
        onClose={handleHamburgerMenu(false)}
        className='navbar-mobile-menu'
      >
        <NavbarMobile
          handleHamburgerMenu={handleHamburgerMenu}
          menuArr={menuArr}
          history={history}
          navigateToUrl={navigateToUrl}
        />
      </Drawer>

      {showAddCreditModal && (
        <AddUpdateCreditModal
          showAddCreditModal={showAddCreditModal}
          closeModal={() => setShowAddCreditModal(false)}
        />
      )}

      {showPurchaseCreditModal && (
        <PurchaseCreditModal
          showPurchaseCreditModal={showPurchaseCreditModal}
          closeModal={() => setShowPurchaseCreditModal(false)}
        />
      )}
    </>
  );
};

function mapStateToProps(state) {
  const { role, subUserRole, username, profilePic, companyName, companyLogo } =
    state.authorizationReducer;
  const { notifications } = state.notificationReducer;
  const { candidates: candidate, editAccount, creditLogList } = state;

  return {
    role,
    subUserRole,
    profilePic,
    companyName,
    companyLogo,
    username,
    notifications,
    candidate,
    editAccount,
    creditLogList,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      logoff: bindActionCreators(authorizationActions.logoff, dispatch),
      verify: bindActionCreators(authorizationActions.verify, dispatch),
      showSuccessSnackbar: bindActionCreators(showSuccessSnackbar, dispatch),
      getNotification: bindActionCreators(getNotification, dispatch),
      updateProjectAddModalOpen: bindActionCreators(
        updateProjectAddModalOpenAction,
        dispatch
      ),
      projectTypeModalOpen: bindActionCreators(
        projectTypeModalOpenAction,
        dispatch
      ),
      updateCompanyAddModalOpen: bindActionCreators(
        updateCompanyAddModalOpenAction,
        dispatch
      ),
      updateCompanyUserAddModalOpen: bindActionCreators(
        updateCompanyUserAddModalOpenAction,
        dispatch
      ),
      addCreditsModalOpen: bindActionCreators(
        addCreditsModalOpenAction,
        dispatch
      ),
      generateOrderFormAddModalOpen: bindActionCreators(
        generateOrderFormAddModalOpenAction,
        dispatch
      ),
    },
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navbar));
