import { UserTypes } from './roles';

export const HTTP_STATUS_UNAUTHORIZED = 401;
export const HTTP_STATUS_FORBIDDEN = 403;
export const HTTP_STATUS_NOT_FOUND = 404;
export const HTTP_STATUS_BAD_REQUEST = 400;
export const HTTP_STATUS_UNPROCESSABLE_ENTITY = 422;
export const HTTP_STATUS_SUCCESS = 200;
export const URGENT_CONSULT_EMAIL_TYPE = 1;
export const CALENDAR_EMAIL_TYPE = 2;
export const MEDICAL_ADVISE_EMAIL_TYPE = 3;
export const PENDING_FOR_VERIFICATION = 0;
export const EMAIL_VERIFIED = 1;
export const ACCOUNT_APPROVED = 2;
export const URL_ARRAY_SEPARATOR = 'bracket';
export const URL_DISABLED_FILTER_FLAG = 'disabled';

// Company types
export const COMPANY_TYPES = [UserTypes.Company, UserTypes.RaasCompany];

export const COMPANY_TYPES_CONST = {
  NORMAL: 1,
  RAAS: 2,
};

// Date formats
export const STANDARD_DATE_FORMAT = 'yyyy-MM-dd';
export const PROJECT_LIST_DATE_FORMAT = 'yy/MM/dd';
export const STANDARD_DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm:ss';
export const READABLE_DATE_TIME_FORMAT = 'yyyy-MM-dd K:mm:sb';

// Locale
export const DEFAULT_LOCALE = 'en-SG';

// Currency
export const DEFAULT_CURRENCY_CODE = 'SGD';

export const PAGE_OPTIONS = {
  TEN: 10,
  TWENTY: 20,
  FIFTY: 50,
};

export const PAGE_SIZES = {
  TWENTY_FIVE: 25,
};

export const PROJECT_STAGES_CONST = {
  ACTIVE: 0,
  PAUSED: 1,
  INACTIVE: 3,
};

export const PROJECT_ITEM_STATE_CONST = {
  SUGGESTED: 0,
  QUALIFY: 1,
  SHORTLIST: 2,
  INTERVIEWING: 3,
  INTERVIEWING2: 7,
  OFFERED: 4,
  PLACED: 5,
  REJECTED: 6,
};

export const COMPANY_STATUS_CONST = {
  PENDING_REQUEST: 0,
  ACTIVE: 1,
  INACTIVE: 2,
};

export const COMPANY_USER_STATUS_CONST = {
  PENDING_REQUEST: 0,
  ACTIVE: 1,
  INACTIVE: 2,
};

export const COMPANY_USER_ROLES_CONST = {
  ADMIN: 1,
  MEMBER: 2,
};

export const ADMIN_STATUS_CONST = {
  ACTIVE: 1,
  INACTIVE: 0,
};

export const ADMIN_TYPES_CONST = {
  GENERAL: 1,
  ADVOCATE: 2,
};

export const CANDIDATE_STATUS_CONST = {
  PENDING_REQUEST: 0,
  ACTIVE: 1,
  INACTIVE: 2,
  SEMI_ACTIVE: 3,
  PENDING_ACTIVATION: 4,
};

export const TAG_STATUS_CONST = {
  ACTIVE: 1,
  INACTIVE: 0,
};

export const PROJECT_ALL_COMPANY_USER_ACCESS_TYPE = {
  FULL_ACCESS: 1,
  NO_ACCESS: 0,
};

export const MAX_TOP_SKILLS_ALLOWED = 5;

export const CANDIDATE_FLOW_ORDER = [
  'signup',
  'parser',
  'experience',
  'skills',
  'seeking',
  'last',
];

export const S3_BUCKET_AVAIL_ENV = [
  'dev',
  'production',
  'uat',
  'qa',
  'sandbox',
];

export const COMMUNITY_URL =
  'https://community.gritsearch.com/users/sign_in?post_login_redirect=https%3A%2F%2Fcommunity.gritsearch.com%2Fc%2Fgeneral';

export const FRESHLY_BAKED_URL = 'https://freshlybaked.ai/';

export const projectCandidateStatus = {
  0: 'Open',
  1: 'Longlist',
  2: 'Shortlist',
  3: 'Interviewed',
  7: 'Interviewed2',
  4: 'Offered',
  5: 'Placed',
  6: 'Rejected',
};

export const urlParams = {
  interviewRequestToken: 'irt',
  accessValue: 'av',
  responseMethod: 'm',
};

// Max salary rate
export const MAX_SALARY_RATE = 1000000000;

export const COMPANY_INTEGRATION_TYPE = {
  GREENHOUSE: 1,
  LEVER: 2,
  LEVERSANDBOX: 3, // Reserved for sandbox environment
  JAZZHR: 4,
  TALEO: 5,
  BULLHORN: 6,
  AVATURE: 7,
  WORKABLE: 8,
  BAMBOOHR: 9,
  RECRUITEE: 10,
  JOBVITE: 11,
  MANATAL: 12,
  FRESHTEAM: 13,
  BREEZY: 14,
  ICIMS: 15,
  SMARTRECRUITERS: 16,
  ZOHORECRUIT: 17,
  RECRUITLY: 18,
  OTHER: 19,
};

export const COMPANY_INTEGRATION_LIST = {
  [COMPANY_INTEGRATION_TYPE.GREENHOUSE]: {
    id: COMPANY_INTEGRATION_TYPE.GREENHOUSE,
    label: 'Greenhouse',
  },
  [COMPANY_INTEGRATION_TYPE.LEVER]: {
    id: COMPANY_INTEGRATION_TYPE.LEVER,
    label: 'Lever',
  },
  [COMPANY_INTEGRATION_TYPE.JAZZHR]: {
    id: COMPANY_INTEGRATION_TYPE.JAZZHR,
    label: 'JazzHR',
  },
  [COMPANY_INTEGRATION_TYPE.TALEO]: {
    id: COMPANY_INTEGRATION_TYPE.TALEO,
    label: 'Taleo',
  },
  [COMPANY_INTEGRATION_TYPE.BULLHORN]: {
    id: COMPANY_INTEGRATION_TYPE.BULLHORN,
    label: 'Bullhorn',
  },
  [COMPANY_INTEGRATION_TYPE.AVATURE]: {
    id: COMPANY_INTEGRATION_TYPE.AVATURE,
    label: 'Avature',
  },
  [COMPANY_INTEGRATION_TYPE.WORKABLE]: {
    id: COMPANY_INTEGRATION_TYPE.WORKABLE,
    label: 'Workable',
  },
  [COMPANY_INTEGRATION_TYPE.BAMBOOHR]: {
    id: COMPANY_INTEGRATION_TYPE.BAMBOOHR,
    label: 'BambooHR',
  },
  [COMPANY_INTEGRATION_TYPE.RECRUITEE]: {
    id: COMPANY_INTEGRATION_TYPE.RECRUITEE,
    label: 'Recruitee',
  },
  [COMPANY_INTEGRATION_TYPE.JOBVITE]: {
    id: COMPANY_INTEGRATION_TYPE.JOBVITE,
    label: 'Jobvite',
  },
  [COMPANY_INTEGRATION_TYPE.MANATAL]: {
    id: COMPANY_INTEGRATION_TYPE.MANATAL,
    label: 'Manatal',
  },
  [COMPANY_INTEGRATION_TYPE.FRESHTEAM]: {
    id: COMPANY_INTEGRATION_TYPE.FRESHTEAM,
    label: 'Freshteam',
  },
  [COMPANY_INTEGRATION_TYPE.BREEZY]: {
    id: COMPANY_INTEGRATION_TYPE.BREEZY,
    label: 'Breezy',
  },
  [COMPANY_INTEGRATION_TYPE.ICIMS]: {
    id: COMPANY_INTEGRATION_TYPE.ICIMS,
    label: 'iCIMs',
  },
  [COMPANY_INTEGRATION_TYPE.SMARTRECRUITERS]: {
    id: COMPANY_INTEGRATION_TYPE.SMARTRECRUITERS,
    label: 'SmartRecruiters',
  },
  [COMPANY_INTEGRATION_TYPE.ZOHORECRUIT]: {
    id: COMPANY_INTEGRATION_TYPE.ZOHORECRUIT,
    label: 'Zoho Recruit',
  },
  [COMPANY_INTEGRATION_TYPE.RECRUITLY]: {
    id: COMPANY_INTEGRATION_TYPE.RECRUITLY,
    label: 'Recruitly',
  },
  [COMPANY_INTEGRATION_TYPE.OTHER]: {
    id: COMPANY_INTEGRATION_TYPE.OTHER,
    label: 'Other',
  },
};

export const DEBOUNCE_SEARCH_HANDLER_WAITING_TIME = 500;

export const THROTTLE_SEARCH_HANDLER_WAITING_TIME = 1000;

export const MAX_NO_OF_FREE_PROJECTS_FOR_RAAS = 1;

export const CREDIT_RATE = { currency: 'US', value: 999 };

export const GUID_TITLES = {
  USER: 'User:',
  PROJECT: 'Project:',
  COMPANY: 'Company:',
  CANDIDATE: 'Candidate:',
  ADMIN: 'Admin:',
  CREDIT: 'Credit:',
};

export const CREDITS_DISCOUNT_RANGE_ARR = [
  {
    MIN: 0,
    MAX: 9,
    DISCOUNT: 0,
  },
  {
    MIN: 10,
    MAX: 19,
    DISCOUNT: 20,
  },
];

export const PROJECT_FOR = {
  COMPANY: 0,
  MYSELF: 1,
};

export const PROJECT_TYPES = {
  FREE: 1,
  POWER: 2,
};

export const WORKING_MODES = {
  REMOTE: 1,
  ONSITE: 2,
  HYBRID: 3,
};

export const CAN_SPONSOR_VISA = {
  YES: 1,
  NO: 0,
};

export const RELOCATION_APPLICABLE = {
  YES: 1,
  NO: 0,
};

export const CANDIDATE_REJECT_RECOMMEND_TO_OTHER_PROJECT_OPTION = {
  YES: 1,
  NO: 0,
};

export const NOTICE_PERIOD = {
  IMMEDIATE: 0,
  ONE_MONTH: 1,
  TWO_MONTH: 2,
  THREE_MONTH: 3,
};

export const CREDITS_LOG_ACTION_TYPES = {
  CREDITS_ADDED_BY_GRIT_ADMIN: 1,
  CREDITS_UPDATED_BY_GRIT_ADMIN: 2,
  CREDITS_DELETED_BY_GRIT_ADMIN: 3,
  CREDITS_DEDUCTED_FOR_PROJECT_EXPIRY: 4,
  CREDITS_DEDUCTED_FOR_PROJECT_CREATION: 5,
  CREDITS_DEDUCTED_FOR_PROJECT_UPGRADE: 6,
  CREDITS_DEDUCTED_FOR_PROJECT_RENEWAL: 7,
  CREDITS_DEDUCTED_FOR_GUARANTEE_CANDIDATE: 8,
  CREDITS_ADDED_BY_COMPANY_USER: 9,
};

// https://developers.facebook.com/docs/whatsapp/on-premises/reference/messages#parameters
export const CrispWhatsAppCharacter = {
  HEADER: 60,
  BODY: 1024,
  FOOTER: 60,
};

export const RATING = (length) => {
  const ratingArray = [];
  if (length > 0) {
    for (let i = 1; i <= length; i += 1) {
      ratingArray.push({
        value: i,
        name: i,
      });
    }
  }

  return ratingArray;
};

export const NOTE_SHARE_OPTION = {
  AdminOnly: 1,
  AdminAndCompany: 2,
};

export const interviewDurations = [
  { id: 1, label: '15 mins' },
  { id: 2, label: '30 mins' },
  { id: 3, label: '45 mins' },
  { id: 4, label: '60 mins' },
  { id: 5, label: '75 mins' },
  { id: 6, label: '90 mins' },
  { id: 7, label: '105 mins' },
  { id: 8, label: '120 mins' },
];

export const FILTERABLE_DASHBOARD_TYPE = {
  StageActivity: 1,
  AssignActivity: 2,
  ActivityPerWeek: 3,
  ProjectOverview: 4,
  InterviewList: 5,
};
